<template>
  <div class="transactionRules">
    <h3 class="transactionRulesTitle">
      商城交易规则
    </h3>
    <!-- 交易时间 -->
    <!-- <div class="tradingSubtitle">
      <i />
      <span>交易时间</span>
      <el-switch
        v-model="value"
        active-color="#13ce66"
        inactive-color="#909399"
      >
      </el-switch>
      <p>
        交易时间开启，商城前台交易将受到已设置的时间限定；交易时间关闭，商城前台交易将不受时间限制
      </p>
    </div>
    <div class="tradeTime">
      <span>选择交易日</span>
      <el-radio v-model="value" label="1">仅工作日</el-radio>
      <el-radio v-model="value" label="2">每天</el-radio>
    </div>
    <div class="tradeTime">
      <span>选择时间</span>
      <el-time-picker
        is-range
        v-model="value"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
      >
      </el-time-picker>
    </div>
    <div class="tradeTime">
      <span class="editButton" :loading="$store.state.loading">编辑</span>
    </div> -->
    <!-- 支付规则 -->
    <div class="tradingSubtitle interval">
      <i />
      <span>支付规则</span>
      <span class="addButton" @click="addAndEditTrading">添加规则</span>
    </div>
    <div class="paymentList">
      <div v-for="(item, index) in paymentList" :key="index" class="itemBox">
        <!-- 上面 -->
        <div class="itemTitle">
          <img src="@/assets/img/head_portrait.png" alt="图片">
          <p>{{ item.tradePayRule.settleBankName }}</p>
          <span @click="addAndEditTrading(item)">编辑</span>
        </div>
        <!-- 下面部分 擦 数据结构没写对  -->
        <div class="modeBox">
          <div class="left">
            <h4>支付方式</h4>
            <span v-if="item.tradeAllPayRuleStyle.payStyle==='00'">全款支付</span>
            <span v-if="item.tradeBondPayRuleStyle.payStyle==='01'">保证金预付<b v-if="item.tradeBondPayRuleStyle.bondRate">({{ item.tradeBondPayRuleStyle.bondRate }}%)</b></span>
          </div>
          <div class="right">
            <h4>交收方式</h4>
            <span v-if="item.tradeAllPayRuleStyle.payStyle==='00'">{{ item.tradeAllPayRuleStyle.settlementStyle==='00'?'一次性交收':'可多次性交收' }}</span>
            <span v-if="item.tradeBondPayRuleStyle.payStyle==='01'">{{ item.tradeBondPayRuleStyle.settlementStyle==='00'?'一次性交收':'可多次性交收' }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 配送规则 -->
    <div class="tradingSubtitle interval">
      <i />
      <span>配送规则</span>
    </div>

    <table class="modeDistribution">
      <tr class="header">
        <td class="td1">
          挂牌来源
        </td>
        <td class="td2">
          支持的配送方式
        </td>
        <td class="td3">
          操作
        </td>
      </tr>
      <tr v-for="(item, index) in distributionRulesList" :key="index">
        <td class="td1">
          {{ item.deliveryRule === "00" ? "" : "非" }}库存挂牌
        </td>
        <td class="td2">
          <span>商家配送<i
            v-if="item.merchantDistribution === 1"
            class="icon el-icon-check"
          /><i v-else class="icon el-icon-close" /></span>
          <span>过户<i v-if="item.transfer === 1" class="icon el-icon-check" /><i v-else class="icon el-icon-close" /></span>
          <span>买家自提<i
            v-if="item.buyerPickUp === 1"
            class="icon el-icon-check"
          /><i v-else class="icon el-icon-close" /></span>
        </td>
        <td class="td3">
          <span @click="editDistributionRules(item)">编辑</span>
        </td>
      </tr>
    </table>
    <!-- 集采规则 -->
    <div class="tradingSubtitle interval">
      <i />
      <span>集采规则</span>
      <span v-if="isEditCollection" class="addButton" @click="editCollectionRules">编辑</span>
      <span v-if="!isEditCollection" class="addButton" @click="saveCollectionRules">保存</span>
      <span v-if="!isEditCollection" class="addButton" @click="cancelCollectionRules">取消</span>
    </div>
    <div class="collection-rules">
      集采尾款缴纳截止时间后<el-input v-model="autoEndDay" :disabled="isEditCollection" class="product-pick" placeholder="" />个自然日内，如商家未进行确认/终止集采操作，则系统自动终止
    </div>
    <!-- 添加与编辑支付规则 -->
    <el-dialog
      width="540px"
      :title="!ruleFrom.type ? '编辑支付规则' : '添加支付规则'"
      :visible.sync="paymentVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleFrom" :model="ruleFrom">
        <el-form-item
          :rules="[{ required: true }]"
          label="选择银行"
          label-width="110px"
        >
          <el-select
            v-model="ruleFrom.tradePayRule.settleBankId"
            placeholder="请选择"
            :disabled="!ruleFrom.type"
            @change="getSettle"
          >
            <el-option
              v-for="item in $store.getters.getDictionaryItem('settleBankName')"
              :key="item.dictId"
              :label="item.dictName"
              :value="item.dictId"
            >
              {{ item.dictName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" label-width="110px">
          <div class="patternPayment">
            <div>
              <el-checkbox
                v-model="ruleFrom.tradeAllPayRuleStyle.payStyle1"
              >
                全款支付
              </el-checkbox>
              <div class="type">
                <el-radio
                  v-model="ruleFrom.tradeAllPayRuleStyle.settlementStyle"
                  label="00"
                >
                  仅一次性交收
                </el-radio>
                <el-radio
                  v-model="ruleFrom.tradeAllPayRuleStyle.settlementStyle"
                  label="01"
                >
                  可多次交收
                </el-radio>
              </div>
              <el-checkbox
                v-model="ruleFrom.tradeBondPayRuleStyle.payStyle1"
              >
                保证金预付
              </el-checkbox>
              <div class="type">
                <el-radio
                  v-model="ruleFrom.tradeBondPayRuleStyle.settlementStyle"
                  label="00"
                >
                  仅一次性交收
                </el-radio>
                <el-radio
                  v-model="ruleFrom.tradeBondPayRuleStyle.settlementStyle"
                  label="01"
                >
                  可多次交收
                </el-radio>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="预付比例(%)" label-width="110px">
          <el-input
            v-model="ruleFrom.tradeBondPayRuleStyle.bondRate"
            type="number"
            placeholder="请填写保证金预付比例"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="paymentVisible = false"
        >
          取 消
        </el-button>
        <el-button
          size="small"
          type="primary"
          :loading="$store.state.loading"
          @click="submitPayment"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 编辑配送规则 -->
    <el-dialog
      width="540px"
      title="编辑配送规则"
      :visible.sync="distributionVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="ruleFrom">
        <p class="source">
          挂牌来源={{
            distributionRulesObj.deliveryRule === "00" ? "" : "非"
          }}库存挂牌时，
        </p>
        <el-form-item
          :rules="[{ required: true }]"
          label="配送方式"
          label-width="110px"
        >
          <div class="patternPayment">
            <el-checkbox
              v-model="distributionRulesObj.merchantDistribution"
              label="商家配送"
            >
              商家配送
            </el-checkbox>
            <el-checkbox
              v-model="distributionRulesObj.transfer"
              label="过户"
              :disabled="distributionRulesObj.deliveryRule === '01'"
            >
              过户
            </el-checkbox>
            <el-checkbox
              v-model="distributionRulesObj.buyerPickUp"
              label="买家自提"
              :disabled="distributionRulesObj.deliveryRule === '01'"
            >
              买家自提
            </el-checkbox>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          class="widen-button"
          @click="distributionVisible = false"
        >
          取 消
        </el-button>
        <el-button
          size="small"
          class="widen-button"
          type="primary"
          :loading="$store.state.loading"
          @click="submitTradeDeliveryRule"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  tradePayList,
  queryDeliveryRule,
  deliveryRuleUpdate,
  addAndEditTrade,
  getCollectionRules,
  updateCollectionRules
} from '@/api/trade'
export default {
  data() {
    return {
      isEditCollection: true,
      ruleFrom: {
        tradePayRule: {},
        tradeAllPayRuleStyle: {},
        tradeBondPayRuleStyle: {}
      },
      distributionRulesObj: {},
      distributionRulesList: [{}],
      paymentList: [],
      fullPayment: false,
      preimburse: false,
      paymentVisible: false,
      distributionVisible: false,
      value: '',
      autoEndDay: ''
    }
  },
  mounted() {
    this.gettradePayList()
    this.getDeliveryRule()
    this.getCollectionRules()
  },
  methods: {
    getCollectionRules() {
      getCollectionRules(res => {
        this.autoEndDay = res.data.autoEndDay
        console.log(this.autoEndDay)
      })
    },
    editCollectionRules() {
      this.isEditCollection = false
    },
    cancelCollectionRules() {
      getCollectionRules(res => {
        this.$message.success('取消成功！！！')
        this.isEditCollection = true
        this.autoEndDay = res.data.autoEndDay
      }, () => {
        this.isEditCollection = true
      })
    },
    saveCollectionRules() {
      updateCollectionRules(this.autoEndDay, res => {
        this.$message.success('保存成功！！！')
        this.isEditCollection = true
      }, () => {
        this.isEditCollection = true
      })
    },
    // 获取银行的名字？
    getSettle(id) {
      const arr = this.$store.getters.getDictionaryItem('settleBankName')
      const obj = arr.find(item => item.dictId === id)
      this.ruleFrom.tradePayRule.settleBankName = obj.dictName
    },
    // 配送规则编辑确认
    submitTradeDeliveryRule() {
      if (!this.distributionRulesObj.buyerPickUp && !this.distributionRulesObj.merchantDistribution && !this.distributionRulesObj.transfer) return this.$message.warning('请选择配送规则')
      const obj = { ...this.distributionRulesObj }
      obj.buyerPickUp = this.distributionRulesObj.buyerPickUp ? 1 : 0
      obj.merchantDistribution = this.distributionRulesObj.merchantDistribution
        ? 1
        : 0
      obj.transfer = this.distributionRulesObj.transfer ? 1 : 0
      deliveryRuleUpdate(obj, () => {
        this.getDeliveryRule()
        this.distributionVisible = false
        this.$message.success('成功')
      })
    },
    // 配送规则编辑
    editDistributionRules(row) {
      this.distributionVisible = true
      this.distributionRulesObj = { ...row }
      this.distributionRulesObj.buyerPickUp =
        this.distributionRulesObj.buyerPickUp === 1
      this.distributionRulesObj.merchantDistribution =
        this.distributionRulesObj.merchantDistribution === 1
      this.distributionRulesObj.transfer =
        this.distributionRulesObj.transfer === 1
    },
    // 配送规则获取
    getDeliveryRule() {
      queryDeliveryRule(res => {
        this.distributionRulesList = [...res.data]
      })
    },
    // 添加与编辑规则确定
    submitPayment() {
      this.$refs['ruleFrom'].validate(valid => {
        if (valid) {
          //  如果选了就赋值上  没选就为空  不要赋值了
          this.ruleFrom.tradeAllPayRuleStyle = this.ruleFrom
            .tradeAllPayRuleStyle.payStyle1
            ? { ...this.ruleFrom.tradeAllPayRuleStyle, payStyle: '00' }
            : {}
          this.ruleFrom.tradeBondPayRuleStyle = this.ruleFrom
            .tradeBondPayRuleStyle.payStyle1
            ? { ...this.ruleFrom.tradeBondPayRuleStyle, payStyle: '01' }
            : {}
          addAndEditTrade(this.ruleFrom, () => {
            this.gettradePayList()
            this.paymentVisible = false
            this.$message.success('成功')
          })
        }
      })
    },
    // 添加与编辑规则
    addAndEditTrading(row) {
      this.paymentVisible = true
      if (row.tradePayRuleStyleList) {
        this.ruleFrom = JSON.parse(JSON.stringify(row))
        return
      }
      this.ruleFrom = {
        type: true,
        tradePayRule: {},
        tradeAllPayRuleStyle: {},
        tradeBondPayRuleStyle: {}
      }
    },
    // 支付规则获取
    gettradePayList() {
      tradePayList(res => {
        this.paymentList = [...res.data]
        this.paymentList.forEach(item => {
          if (
            item.tradePayRuleStyleList &&
            item.tradePayRuleStyleList.length > 0
          ) {
            item.tradePayRuleStyleList.forEach(val => {
              // 先把数据渲染好
              if (val.payStyle === '00') {
                val.payStyle1 = true
                item.tradeAllPayRuleStyle = { ...val }
                return
              }
              if (val.payStyle === '01') {
                val.payStyle1 = true
                item.tradeBondPayRuleStyle = { ...val }
                return
              }
              item.tradeAllPayRuleStyle = {}
              item.tradeBondPayRuleStyle = {}
            })
            return
          }
          item.tradeAllPayRuleStyle = {}
          item.tradeBondPayRuleStyle = {}
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.transactionRules {
  background-color: #fff;
  padding-bottom: 64px;
  .el-form {
    padding: 0 10px 0 19px;
    .source {
      margin: 12px 0 16px 10px;
      font-size: 14px;
      color: #333;
    }
    .patternPayment {
      .type {
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 60px;
        background-color: #f4f6fa;
        margin: 10px 0 18px;
      }
    }
    .el-form-item__label {
      text-align: left;
      transform: translateX(10px);
    }
    .el-form-item__content {
      line-height: 1;
      .patternPayment,
      .el-checkbox-group {
        margin-top: 10px;
      }
    }
    .is-required .el-form-item__label {
      transform: translateX(0);
    }
  }
  .modeDistribution {
    width: 830px;
    margin-left: 39px;
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
    .header {
      background-color: #f9f9f9;
      td {
        height: 49px;
      }
    }
    td {
      border: 1px solid #e3e3e3;
      height: 42px;
    }
    .td1 {
      width: 266px;
    }
    .td2 {
      width: 363px;
      > span {
        margin-right: 12px;
      }
      .icon {
        margin-left: 4px;
        display: inline-block;
        height: 16px;
        line-height: 16px;
        width: 16px;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        background-color: #c1c1c1;
      }
    }
    .td3 {
      span {
        display: inline-block;
        width: 55px;
        height: 30px;
        line-height: 30px;
        color: #0089ff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  .paymentList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 38px;
    .itemBox {
      margin-right: 12px;
      width: 326px;
      height: 203px;
      box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.16);
      .modeBox {
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
        color: #333;
        font-size: 14px;
        text-align: center;
        height: 132px;
        background-color: #f6f9fc;
        .right,
        .left {
          width: 138px;
          h4 {
            font-weight: 500;
            padding-bottom: 14px;
          }
          span {
            display: inline-block;
            width: 138px;
            height: 38px;
            line-height: 38px;
            background-color: #fff;
            border-radius: 4px;
            margin-bottom: 8px;
          }
          span:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .itemTitle {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 13px 0 19px;
        box-shadow: 6px 6px 6px #f2f2f2;
        img {
          width: 43px;
          height: 40px;
        }
        span {
          cursor: pointer;
          font-size: 14px;
          color: #0089ff;
        }
        p {
          flex: 1;
          color: #333;
          font-size: 16px;
          font-weight: 700;
          padding-left: 10px;
        }
      }
    }
  }
  .tradeTime {
    margin: 32px 0 0 46px;
    color: #333;
    font-size: 14px;
    > span {
      width: 120px;
      display: inline-block;
    }
    .editButton {
      width: 84px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      background-color: #0089ff;
      cursor: pointer;
      transition: all 0.5s;
    }
    .editButton:hover {
      background-color: #007be5;
    }
  }
  .tradingSubtitle {
    margin-left: 19px;
    width: 730px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #333;
    background: linear-gradient(90deg, #e3f2ff 20%, #f8fcff 80%);
    > span {
      margin: 0 18px 0 4px;
    }
    .addButton {
      margin: 0 0 0 15px;
      display: inline-block;
      width: 84px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #ebebeb;
      font-size: 14px;
      color: #0089ff;
      background-color: #fff;
      cursor: pointer;
    }
    > p {
      margin-left: 20px;
      color: #909399;
      font-size: 12px;
    }
    i {
      display: inline-block;
      height: 12px;
      width: 2px;
      transform: translateY(2px);
      background-color: #0089ff;
    }
  }
  .interval {
    margin: 48px 0 35px 19px;
  }
  .transactionRulesTitle {
    padding: 34px 0 30px 36px;
    color: #333;
    font-size: 16px;
    font-weight: 700;
  }
  .collection-rules {
    margin-left: 39px;
    .product-pick {
      width: 50px;
      border-bottom: 1px solid #ccc;
      :deep(.el-input .el-input__inner) {
        border: none !important;
      }
      :deep(.el-input__wrapper) {
          border: none !important;
          box-shadow: none !important;
      }
      :deep(.el-input__inner) {
          border: none !important;
          box-shadow: none !important;
      }
  }
  }

}
</style>
